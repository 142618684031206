<template>
    <b-card class="p-1">
      <div class="mx-2">
        <b-row class="justify-content-between align-items-center">
            <h3 class="ml-1">Reproduções</h3>

          <b-media no-body class="mr-2 mb-2">
            <b-media-aside class="mr-1" >
              <b-avatar size="40" variant="light-primary">
                <feather-icon size="20" icon="MusicIcon"/>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ totalReproducao }}
              </h4>
              <b-card-text class="font-medium-2 mb-0 text-dark">
                Reproduções
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-row>
      </div>
      <b-card-body name="compradorRepeticoes">
        <b-table ref="refUserListTable" class="position-relative" :items="reproducao" responsive :fields="tableColumns" primary-key="data"
          :sort-by.sync="sortBy" show-empty empty-text="Sem resultados" :sort-desc.sync="isSortDirDesc"
          >
          <!-- Column: User -->
  
  
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
  
            <b-col cols="12" sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination v-model="currentPage" :total-rows="totalReproducao" :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="mudarPagina"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BCardBody,
    BCardText,
    BMediaBody,
    BMediaAside,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import VueElementLoading from "vue-element-loading";
  import useJwt from "@/auth/jwt/useJwt";
  
  // CARREGANDO DE PÁGINA PARA API
  
  export default {
    components: {
      BCard,
      BCardBody,
      BCardText,
      BMediaBody,
      BMediaAside,
      BMedia,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BButton,
      BTable,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
  
      vSelect,
      VueElementLoading
    },
    data() {
      return {
        reproducao: [],
        totalReproducao: 0,
        tableColumns: [
          { key: "nomeComposicao", label: "Composição", sortable: true },
          { key: "qtdReproducao", label: "Reproduções", sortable: true },
            
        ],
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        sortBy: "data",
        isSortDirDesc: true,
        refUserListTable: null,
        carregamentoApi: false
      };    
    },
    props: {
      compradorId: String
    },
    created() {
      this.usuarioCompradorReproducaoLista();
    },
    methods: {
        usuarioCompradorReproducaoLista() {
        this.carregamentoApi = true;
        const paginaAtual = this.currentPage - 1;
        useJwt
        .contarQtdReproducao({
          usuarioId: this.compradorId,
          page: paginaAtual,
          size: this.perPage,
          sort: "ASC"
        })
        .then(response => {
          this.totalReproducao = response.data.totalizadorReproducao;
          this.reproducao = response.data.reproducao.content
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.carregamentoApi = false;
          });
      },
      mudarPagina() {
        this.usuarioCompradorReproducaoLista();
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  