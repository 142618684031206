<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <b-tab active>

      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Informações</span>
      </template>

      <comprador-informacoes 
        :comprador-id="compradorId"
      />
    </b-tab>

    
   <b-tab>
      <template #title>
        <feather-icon
          icon="MusicIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Audições</span>
      </template>

      <comprador-audicoes 
        :comprador-id="compradorId"
      />
    </b-tab>
  
    <b-tab>
        <template #title>
          <feather-icon
            icon="MusicIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Reproducões</span>
        </template>

        <comprador-reproducoes 
          :comprador-id="compradorId"
        />
    </b-tab>
     
     
    <b-tab>
        <template #title>
          <feather-icon
            icon="StarIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Favoritos</span>
        </template>

        <comprador-favoritos 
          :comprador-id="compradorId"
        />
    </b-tab>   
  <!--/ Geral Tab -->

    

  </b-tabs>
</template>

<script>
import router from '@/router'
import { BTabs, BTab } from 'bootstrap-vue'
import CompradorInformacoes from './CompradorInformacoes.vue'
import CompradorAudicoes from './CompradorAudicoes.vue'
import CompradorReproducoes from './CompradorReproducoes.vue'
import CompradorFavoritos from './CompradorFavoritos.vue'

export default {
  components: {
    BTabs,
    BTab,
    CompradorInformacoes,
    CompradorAudicoes,
    CompradorReproducoes,
    CompradorFavoritos
  },
  data() {
    return {
      compradorId: router.currentRoute.params.id
    }
  }

}
</script>
